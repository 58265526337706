import { default as indexxdSquRIdnJMeta } from "/codebuild/output/src2383342895/src/sense-us.app/pages/[projectId]/[roundId]/index.vue?macro=true";
import { default as resultsW1s7s2qdZjMeta } from "/codebuild/output/src2383342895/src/sense-us.app/pages/[projectId]/[roundId]/results.vue?macro=true";
import { default as indexElx9jo9dd5Meta } from "/codebuild/output/src2383342895/src/sense-us.app/pages/[projectId]/index.vue?macro=true";
import { default as noneO49Hs8IhiqMeta } from "/codebuild/output/src2383342895/src/sense-us.app/pages/[projectId]/none.vue?macro=true";
import { default as profileT777eqtZ2jMeta } from "/codebuild/output/src2383342895/src/sense-us.app/pages/[projectId]/profile.vue?macro=true";
import { default as imageVGHqmDf6CfMeta } from "/codebuild/output/src2383342895/src/sense-us.app/pages/admin/answer/[answerId]/image.vue?macro=true";
import { default as indexa9H9DSFNiUMeta } from "/codebuild/output/src2383342895/src/sense-us.app/pages/admin/index.vue?macro=true";
import { default as aggregateGXifjQal0tMeta } from "/codebuild/output/src2383342895/src/sense-us.app/pages/admin/project/[projectId]/aggregate.vue?macro=true";
import { default as indexoBJezHpNHsMeta } from "/codebuild/output/src2383342895/src/sense-us.app/pages/admin/project/[projectId]/index.vue?macro=true";
import { default as profile8v7YSpa6jrMeta } from "/codebuild/output/src2383342895/src/sense-us.app/pages/admin/project/[projectId]/profile.vue?macro=true";
import { default as resultswsoFpz0NgkMeta } from "/codebuild/output/src2383342895/src/sense-us.app/pages/admin/project/[projectId]/results.vue?macro=true";
import { default as _91roundId_93Dq6yJIxKMOMeta } from "/codebuild/output/src2383342895/src/sense-us.app/pages/admin/project/[projectId]/round/[roundId].vue?macro=true";
import { default as createacr79XtyvXMeta } from "/codebuild/output/src2383342895/src/sense-us.app/pages/admin/project/[projectId]/round/create.vue?macro=true";
import { default as _91userId_938Hb9jkmGtxMeta } from "/codebuild/output/src2383342895/src/sense-us.app/pages/admin/project/[projectId]/users/[userId].vue?macro=true";
import { default as indexxSggPLRZvdMeta } from "/codebuild/output/src2383342895/src/sense-us.app/pages/admin/project/[projectId]/users/index.vue?macro=true";
import { default as createC7q1gcR93zMeta } from "/codebuild/output/src2383342895/src/sense-us.app/pages/admin/project/create.vue?macro=true";
import { default as indexN4AtGORVQ4Meta } from "/codebuild/output/src2383342895/src/sense-us.app/pages/index.vue?macro=true";
import { default as login07hQJgC3aPMeta } from "/codebuild/output/src2383342895/src/sense-us.app/pages/login.vue?macro=true";
import { default as registerjPkZHeOMcQMeta } from "/codebuild/output/src2383342895/src/sense-us.app/pages/register.vue?macro=true";
export default [
  {
    name: indexxdSquRIdnJMeta?.name ?? "projectId-roundId___en",
    path: indexxdSquRIdnJMeta?.path ?? "/:projectId()/:roundId()",
    meta: indexxdSquRIdnJMeta || {},
    alias: indexxdSquRIdnJMeta?.alias || [],
    redirect: indexxdSquRIdnJMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src2383342895/src/sense-us.app/pages/[projectId]/[roundId]/index.vue").then(m => m.default || m)
  },
  {
    name: indexxdSquRIdnJMeta?.name ?? "projectId-roundId___nl",
    path: indexxdSquRIdnJMeta?.path ?? "/nl/:projectId()/:roundId()",
    meta: indexxdSquRIdnJMeta || {},
    alias: indexxdSquRIdnJMeta?.alias || [],
    redirect: indexxdSquRIdnJMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src2383342895/src/sense-us.app/pages/[projectId]/[roundId]/index.vue").then(m => m.default || m)
  },
  {
    name: indexxdSquRIdnJMeta?.name ?? "projectId-roundId___de",
    path: indexxdSquRIdnJMeta?.path ?? "/de/:projectId()/:roundId()",
    meta: indexxdSquRIdnJMeta || {},
    alias: indexxdSquRIdnJMeta?.alias || [],
    redirect: indexxdSquRIdnJMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src2383342895/src/sense-us.app/pages/[projectId]/[roundId]/index.vue").then(m => m.default || m)
  },
  {
    name: indexxdSquRIdnJMeta?.name ?? "projectId-roundId___es",
    path: indexxdSquRIdnJMeta?.path ?? "/es/:projectId()/:roundId()",
    meta: indexxdSquRIdnJMeta || {},
    alias: indexxdSquRIdnJMeta?.alias || [],
    redirect: indexxdSquRIdnJMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src2383342895/src/sense-us.app/pages/[projectId]/[roundId]/index.vue").then(m => m.default || m)
  },
  {
    name: resultsW1s7s2qdZjMeta?.name ?? "projectId-roundId-results___en",
    path: resultsW1s7s2qdZjMeta?.path ?? "/:projectId()/:roundId()/results",
    meta: resultsW1s7s2qdZjMeta || {},
    alias: resultsW1s7s2qdZjMeta?.alias || [],
    redirect: resultsW1s7s2qdZjMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src2383342895/src/sense-us.app/pages/[projectId]/[roundId]/results.vue").then(m => m.default || m)
  },
  {
    name: resultsW1s7s2qdZjMeta?.name ?? "projectId-roundId-results___nl",
    path: resultsW1s7s2qdZjMeta?.path ?? "/nl/:projectId()/:roundId()/results",
    meta: resultsW1s7s2qdZjMeta || {},
    alias: resultsW1s7s2qdZjMeta?.alias || [],
    redirect: resultsW1s7s2qdZjMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src2383342895/src/sense-us.app/pages/[projectId]/[roundId]/results.vue").then(m => m.default || m)
  },
  {
    name: resultsW1s7s2qdZjMeta?.name ?? "projectId-roundId-results___de",
    path: resultsW1s7s2qdZjMeta?.path ?? "/de/:projectId()/:roundId()/results",
    meta: resultsW1s7s2qdZjMeta || {},
    alias: resultsW1s7s2qdZjMeta?.alias || [],
    redirect: resultsW1s7s2qdZjMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src2383342895/src/sense-us.app/pages/[projectId]/[roundId]/results.vue").then(m => m.default || m)
  },
  {
    name: resultsW1s7s2qdZjMeta?.name ?? "projectId-roundId-results___es",
    path: resultsW1s7s2qdZjMeta?.path ?? "/es/:projectId()/:roundId()/results",
    meta: resultsW1s7s2qdZjMeta || {},
    alias: resultsW1s7s2qdZjMeta?.alias || [],
    redirect: resultsW1s7s2qdZjMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src2383342895/src/sense-us.app/pages/[projectId]/[roundId]/results.vue").then(m => m.default || m)
  },
  {
    name: indexElx9jo9dd5Meta?.name ?? "projectId___en",
    path: indexElx9jo9dd5Meta?.path ?? "/:projectId()",
    meta: indexElx9jo9dd5Meta || {},
    alias: indexElx9jo9dd5Meta?.alias || [],
    redirect: indexElx9jo9dd5Meta?.redirect || undefined,
    component: () => import("/codebuild/output/src2383342895/src/sense-us.app/pages/[projectId]/index.vue").then(m => m.default || m)
  },
  {
    name: indexElx9jo9dd5Meta?.name ?? "projectId___nl",
    path: indexElx9jo9dd5Meta?.path ?? "/nl/:projectId()",
    meta: indexElx9jo9dd5Meta || {},
    alias: indexElx9jo9dd5Meta?.alias || [],
    redirect: indexElx9jo9dd5Meta?.redirect || undefined,
    component: () => import("/codebuild/output/src2383342895/src/sense-us.app/pages/[projectId]/index.vue").then(m => m.default || m)
  },
  {
    name: indexElx9jo9dd5Meta?.name ?? "projectId___de",
    path: indexElx9jo9dd5Meta?.path ?? "/de/:projectId()",
    meta: indexElx9jo9dd5Meta || {},
    alias: indexElx9jo9dd5Meta?.alias || [],
    redirect: indexElx9jo9dd5Meta?.redirect || undefined,
    component: () => import("/codebuild/output/src2383342895/src/sense-us.app/pages/[projectId]/index.vue").then(m => m.default || m)
  },
  {
    name: indexElx9jo9dd5Meta?.name ?? "projectId___es",
    path: indexElx9jo9dd5Meta?.path ?? "/es/:projectId()",
    meta: indexElx9jo9dd5Meta || {},
    alias: indexElx9jo9dd5Meta?.alias || [],
    redirect: indexElx9jo9dd5Meta?.redirect || undefined,
    component: () => import("/codebuild/output/src2383342895/src/sense-us.app/pages/[projectId]/index.vue").then(m => m.default || m)
  },
  {
    name: noneO49Hs8IhiqMeta?.name ?? "projectId-none___en",
    path: noneO49Hs8IhiqMeta?.path ?? "/:projectId()/none",
    meta: noneO49Hs8IhiqMeta || {},
    alias: noneO49Hs8IhiqMeta?.alias || [],
    redirect: noneO49Hs8IhiqMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src2383342895/src/sense-us.app/pages/[projectId]/none.vue").then(m => m.default || m)
  },
  {
    name: noneO49Hs8IhiqMeta?.name ?? "projectId-none___nl",
    path: noneO49Hs8IhiqMeta?.path ?? "/nl/:projectId()/none",
    meta: noneO49Hs8IhiqMeta || {},
    alias: noneO49Hs8IhiqMeta?.alias || [],
    redirect: noneO49Hs8IhiqMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src2383342895/src/sense-us.app/pages/[projectId]/none.vue").then(m => m.default || m)
  },
  {
    name: noneO49Hs8IhiqMeta?.name ?? "projectId-none___de",
    path: noneO49Hs8IhiqMeta?.path ?? "/de/:projectId()/none",
    meta: noneO49Hs8IhiqMeta || {},
    alias: noneO49Hs8IhiqMeta?.alias || [],
    redirect: noneO49Hs8IhiqMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src2383342895/src/sense-us.app/pages/[projectId]/none.vue").then(m => m.default || m)
  },
  {
    name: noneO49Hs8IhiqMeta?.name ?? "projectId-none___es",
    path: noneO49Hs8IhiqMeta?.path ?? "/es/:projectId()/none",
    meta: noneO49Hs8IhiqMeta || {},
    alias: noneO49Hs8IhiqMeta?.alias || [],
    redirect: noneO49Hs8IhiqMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src2383342895/src/sense-us.app/pages/[projectId]/none.vue").then(m => m.default || m)
  },
  {
    name: profileT777eqtZ2jMeta?.name ?? "projectId-profile___en",
    path: profileT777eqtZ2jMeta?.path ?? "/:projectId()/profile",
    meta: profileT777eqtZ2jMeta || {},
    alias: profileT777eqtZ2jMeta?.alias || [],
    redirect: profileT777eqtZ2jMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src2383342895/src/sense-us.app/pages/[projectId]/profile.vue").then(m => m.default || m)
  },
  {
    name: profileT777eqtZ2jMeta?.name ?? "projectId-profile___nl",
    path: profileT777eqtZ2jMeta?.path ?? "/nl/:projectId()/profile",
    meta: profileT777eqtZ2jMeta || {},
    alias: profileT777eqtZ2jMeta?.alias || [],
    redirect: profileT777eqtZ2jMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src2383342895/src/sense-us.app/pages/[projectId]/profile.vue").then(m => m.default || m)
  },
  {
    name: profileT777eqtZ2jMeta?.name ?? "projectId-profile___de",
    path: profileT777eqtZ2jMeta?.path ?? "/de/:projectId()/profile",
    meta: profileT777eqtZ2jMeta || {},
    alias: profileT777eqtZ2jMeta?.alias || [],
    redirect: profileT777eqtZ2jMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src2383342895/src/sense-us.app/pages/[projectId]/profile.vue").then(m => m.default || m)
  },
  {
    name: profileT777eqtZ2jMeta?.name ?? "projectId-profile___es",
    path: profileT777eqtZ2jMeta?.path ?? "/es/:projectId()/profile",
    meta: profileT777eqtZ2jMeta || {},
    alias: profileT777eqtZ2jMeta?.alias || [],
    redirect: profileT777eqtZ2jMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src2383342895/src/sense-us.app/pages/[projectId]/profile.vue").then(m => m.default || m)
  },
  {
    name: imageVGHqmDf6CfMeta?.name ?? "admin-answer-answerId-image___en",
    path: imageVGHqmDf6CfMeta?.path ?? "/admin/answer/:answerId()/image",
    meta: imageVGHqmDf6CfMeta || {},
    alias: imageVGHqmDf6CfMeta?.alias || [],
    redirect: imageVGHqmDf6CfMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src2383342895/src/sense-us.app/pages/admin/answer/[answerId]/image.vue").then(m => m.default || m)
  },
  {
    name: imageVGHqmDf6CfMeta?.name ?? "admin-answer-answerId-image___nl",
    path: imageVGHqmDf6CfMeta?.path ?? "/nl/admin/answer/:answerId()/image",
    meta: imageVGHqmDf6CfMeta || {},
    alias: imageVGHqmDf6CfMeta?.alias || [],
    redirect: imageVGHqmDf6CfMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src2383342895/src/sense-us.app/pages/admin/answer/[answerId]/image.vue").then(m => m.default || m)
  },
  {
    name: imageVGHqmDf6CfMeta?.name ?? "admin-answer-answerId-image___de",
    path: imageVGHqmDf6CfMeta?.path ?? "/de/admin/answer/:answerId()/image",
    meta: imageVGHqmDf6CfMeta || {},
    alias: imageVGHqmDf6CfMeta?.alias || [],
    redirect: imageVGHqmDf6CfMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src2383342895/src/sense-us.app/pages/admin/answer/[answerId]/image.vue").then(m => m.default || m)
  },
  {
    name: imageVGHqmDf6CfMeta?.name ?? "admin-answer-answerId-image___es",
    path: imageVGHqmDf6CfMeta?.path ?? "/es/admin/answer/:answerId()/image",
    meta: imageVGHqmDf6CfMeta || {},
    alias: imageVGHqmDf6CfMeta?.alias || [],
    redirect: imageVGHqmDf6CfMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src2383342895/src/sense-us.app/pages/admin/answer/[answerId]/image.vue").then(m => m.default || m)
  },
  {
    name: indexa9H9DSFNiUMeta?.name ?? "admin___en",
    path: indexa9H9DSFNiUMeta?.path ?? "/admin",
    meta: indexa9H9DSFNiUMeta || {},
    alias: indexa9H9DSFNiUMeta?.alias || [],
    redirect: indexa9H9DSFNiUMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src2383342895/src/sense-us.app/pages/admin/index.vue").then(m => m.default || m)
  },
  {
    name: indexa9H9DSFNiUMeta?.name ?? "admin___nl",
    path: indexa9H9DSFNiUMeta?.path ?? "/nl/admin",
    meta: indexa9H9DSFNiUMeta || {},
    alias: indexa9H9DSFNiUMeta?.alias || [],
    redirect: indexa9H9DSFNiUMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src2383342895/src/sense-us.app/pages/admin/index.vue").then(m => m.default || m)
  },
  {
    name: indexa9H9DSFNiUMeta?.name ?? "admin___de",
    path: indexa9H9DSFNiUMeta?.path ?? "/de/admin",
    meta: indexa9H9DSFNiUMeta || {},
    alias: indexa9H9DSFNiUMeta?.alias || [],
    redirect: indexa9H9DSFNiUMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src2383342895/src/sense-us.app/pages/admin/index.vue").then(m => m.default || m)
  },
  {
    name: indexa9H9DSFNiUMeta?.name ?? "admin___es",
    path: indexa9H9DSFNiUMeta?.path ?? "/es/admin",
    meta: indexa9H9DSFNiUMeta || {},
    alias: indexa9H9DSFNiUMeta?.alias || [],
    redirect: indexa9H9DSFNiUMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src2383342895/src/sense-us.app/pages/admin/index.vue").then(m => m.default || m)
  },
  {
    name: aggregateGXifjQal0tMeta?.name ?? "admin-project-projectId-aggregate___en",
    path: aggregateGXifjQal0tMeta?.path ?? "/admin/project/:projectId()/aggregate",
    meta: aggregateGXifjQal0tMeta || {},
    alias: aggregateGXifjQal0tMeta?.alias || [],
    redirect: aggregateGXifjQal0tMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src2383342895/src/sense-us.app/pages/admin/project/[projectId]/aggregate.vue").then(m => m.default || m)
  },
  {
    name: aggregateGXifjQal0tMeta?.name ?? "admin-project-projectId-aggregate___nl",
    path: aggregateGXifjQal0tMeta?.path ?? "/nl/admin/project/:projectId()/aggregate",
    meta: aggregateGXifjQal0tMeta || {},
    alias: aggregateGXifjQal0tMeta?.alias || [],
    redirect: aggregateGXifjQal0tMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src2383342895/src/sense-us.app/pages/admin/project/[projectId]/aggregate.vue").then(m => m.default || m)
  },
  {
    name: aggregateGXifjQal0tMeta?.name ?? "admin-project-projectId-aggregate___de",
    path: aggregateGXifjQal0tMeta?.path ?? "/de/admin/project/:projectId()/aggregate",
    meta: aggregateGXifjQal0tMeta || {},
    alias: aggregateGXifjQal0tMeta?.alias || [],
    redirect: aggregateGXifjQal0tMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src2383342895/src/sense-us.app/pages/admin/project/[projectId]/aggregate.vue").then(m => m.default || m)
  },
  {
    name: aggregateGXifjQal0tMeta?.name ?? "admin-project-projectId-aggregate___es",
    path: aggregateGXifjQal0tMeta?.path ?? "/es/admin/project/:projectId()/aggregate",
    meta: aggregateGXifjQal0tMeta || {},
    alias: aggregateGXifjQal0tMeta?.alias || [],
    redirect: aggregateGXifjQal0tMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src2383342895/src/sense-us.app/pages/admin/project/[projectId]/aggregate.vue").then(m => m.default || m)
  },
  {
    name: indexoBJezHpNHsMeta?.name ?? "admin-project-projectId___en",
    path: indexoBJezHpNHsMeta?.path ?? "/admin/project/:projectId()",
    meta: indexoBJezHpNHsMeta || {},
    alias: indexoBJezHpNHsMeta?.alias || [],
    redirect: indexoBJezHpNHsMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src2383342895/src/sense-us.app/pages/admin/project/[projectId]/index.vue").then(m => m.default || m)
  },
  {
    name: indexoBJezHpNHsMeta?.name ?? "admin-project-projectId___nl",
    path: indexoBJezHpNHsMeta?.path ?? "/nl/admin/project/:projectId()",
    meta: indexoBJezHpNHsMeta || {},
    alias: indexoBJezHpNHsMeta?.alias || [],
    redirect: indexoBJezHpNHsMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src2383342895/src/sense-us.app/pages/admin/project/[projectId]/index.vue").then(m => m.default || m)
  },
  {
    name: indexoBJezHpNHsMeta?.name ?? "admin-project-projectId___de",
    path: indexoBJezHpNHsMeta?.path ?? "/de/admin/project/:projectId()",
    meta: indexoBJezHpNHsMeta || {},
    alias: indexoBJezHpNHsMeta?.alias || [],
    redirect: indexoBJezHpNHsMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src2383342895/src/sense-us.app/pages/admin/project/[projectId]/index.vue").then(m => m.default || m)
  },
  {
    name: indexoBJezHpNHsMeta?.name ?? "admin-project-projectId___es",
    path: indexoBJezHpNHsMeta?.path ?? "/es/admin/project/:projectId()",
    meta: indexoBJezHpNHsMeta || {},
    alias: indexoBJezHpNHsMeta?.alias || [],
    redirect: indexoBJezHpNHsMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src2383342895/src/sense-us.app/pages/admin/project/[projectId]/index.vue").then(m => m.default || m)
  },
  {
    name: profile8v7YSpa6jrMeta?.name ?? "admin-project-projectId-profile___en",
    path: profile8v7YSpa6jrMeta?.path ?? "/admin/project/:projectId()/profile",
    meta: profile8v7YSpa6jrMeta || {},
    alias: profile8v7YSpa6jrMeta?.alias || [],
    redirect: profile8v7YSpa6jrMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src2383342895/src/sense-us.app/pages/admin/project/[projectId]/profile.vue").then(m => m.default || m)
  },
  {
    name: profile8v7YSpa6jrMeta?.name ?? "admin-project-projectId-profile___nl",
    path: profile8v7YSpa6jrMeta?.path ?? "/nl/admin/project/:projectId()/profile",
    meta: profile8v7YSpa6jrMeta || {},
    alias: profile8v7YSpa6jrMeta?.alias || [],
    redirect: profile8v7YSpa6jrMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src2383342895/src/sense-us.app/pages/admin/project/[projectId]/profile.vue").then(m => m.default || m)
  },
  {
    name: profile8v7YSpa6jrMeta?.name ?? "admin-project-projectId-profile___de",
    path: profile8v7YSpa6jrMeta?.path ?? "/de/admin/project/:projectId()/profile",
    meta: profile8v7YSpa6jrMeta || {},
    alias: profile8v7YSpa6jrMeta?.alias || [],
    redirect: profile8v7YSpa6jrMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src2383342895/src/sense-us.app/pages/admin/project/[projectId]/profile.vue").then(m => m.default || m)
  },
  {
    name: profile8v7YSpa6jrMeta?.name ?? "admin-project-projectId-profile___es",
    path: profile8v7YSpa6jrMeta?.path ?? "/es/admin/project/:projectId()/profile",
    meta: profile8v7YSpa6jrMeta || {},
    alias: profile8v7YSpa6jrMeta?.alias || [],
    redirect: profile8v7YSpa6jrMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src2383342895/src/sense-us.app/pages/admin/project/[projectId]/profile.vue").then(m => m.default || m)
  },
  {
    name: resultswsoFpz0NgkMeta?.name ?? "admin-project-projectId-results___en",
    path: resultswsoFpz0NgkMeta?.path ?? "/admin/project/:projectId()/results",
    meta: resultswsoFpz0NgkMeta || {},
    alias: resultswsoFpz0NgkMeta?.alias || [],
    redirect: resultswsoFpz0NgkMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src2383342895/src/sense-us.app/pages/admin/project/[projectId]/results.vue").then(m => m.default || m)
  },
  {
    name: resultswsoFpz0NgkMeta?.name ?? "admin-project-projectId-results___nl",
    path: resultswsoFpz0NgkMeta?.path ?? "/nl/admin/project/:projectId()/results",
    meta: resultswsoFpz0NgkMeta || {},
    alias: resultswsoFpz0NgkMeta?.alias || [],
    redirect: resultswsoFpz0NgkMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src2383342895/src/sense-us.app/pages/admin/project/[projectId]/results.vue").then(m => m.default || m)
  },
  {
    name: resultswsoFpz0NgkMeta?.name ?? "admin-project-projectId-results___de",
    path: resultswsoFpz0NgkMeta?.path ?? "/de/admin/project/:projectId()/results",
    meta: resultswsoFpz0NgkMeta || {},
    alias: resultswsoFpz0NgkMeta?.alias || [],
    redirect: resultswsoFpz0NgkMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src2383342895/src/sense-us.app/pages/admin/project/[projectId]/results.vue").then(m => m.default || m)
  },
  {
    name: resultswsoFpz0NgkMeta?.name ?? "admin-project-projectId-results___es",
    path: resultswsoFpz0NgkMeta?.path ?? "/es/admin/project/:projectId()/results",
    meta: resultswsoFpz0NgkMeta || {},
    alias: resultswsoFpz0NgkMeta?.alias || [],
    redirect: resultswsoFpz0NgkMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src2383342895/src/sense-us.app/pages/admin/project/[projectId]/results.vue").then(m => m.default || m)
  },
  {
    name: _91roundId_93Dq6yJIxKMOMeta?.name ?? "admin-project-projectId-round-roundId___en",
    path: _91roundId_93Dq6yJIxKMOMeta?.path ?? "/admin/project/:projectId()/round/:roundId()",
    meta: _91roundId_93Dq6yJIxKMOMeta || {},
    alias: _91roundId_93Dq6yJIxKMOMeta?.alias || [],
    redirect: _91roundId_93Dq6yJIxKMOMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src2383342895/src/sense-us.app/pages/admin/project/[projectId]/round/[roundId].vue").then(m => m.default || m)
  },
  {
    name: _91roundId_93Dq6yJIxKMOMeta?.name ?? "admin-project-projectId-round-roundId___nl",
    path: _91roundId_93Dq6yJIxKMOMeta?.path ?? "/nl/admin/project/:projectId()/round/:roundId()",
    meta: _91roundId_93Dq6yJIxKMOMeta || {},
    alias: _91roundId_93Dq6yJIxKMOMeta?.alias || [],
    redirect: _91roundId_93Dq6yJIxKMOMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src2383342895/src/sense-us.app/pages/admin/project/[projectId]/round/[roundId].vue").then(m => m.default || m)
  },
  {
    name: _91roundId_93Dq6yJIxKMOMeta?.name ?? "admin-project-projectId-round-roundId___de",
    path: _91roundId_93Dq6yJIxKMOMeta?.path ?? "/de/admin/project/:projectId()/round/:roundId()",
    meta: _91roundId_93Dq6yJIxKMOMeta || {},
    alias: _91roundId_93Dq6yJIxKMOMeta?.alias || [],
    redirect: _91roundId_93Dq6yJIxKMOMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src2383342895/src/sense-us.app/pages/admin/project/[projectId]/round/[roundId].vue").then(m => m.default || m)
  },
  {
    name: _91roundId_93Dq6yJIxKMOMeta?.name ?? "admin-project-projectId-round-roundId___es",
    path: _91roundId_93Dq6yJIxKMOMeta?.path ?? "/es/admin/project/:projectId()/round/:roundId()",
    meta: _91roundId_93Dq6yJIxKMOMeta || {},
    alias: _91roundId_93Dq6yJIxKMOMeta?.alias || [],
    redirect: _91roundId_93Dq6yJIxKMOMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src2383342895/src/sense-us.app/pages/admin/project/[projectId]/round/[roundId].vue").then(m => m.default || m)
  },
  {
    name: createacr79XtyvXMeta?.name ?? "admin-project-projectId-round-create___en",
    path: createacr79XtyvXMeta?.path ?? "/admin/project/:projectId()/round/create",
    meta: createacr79XtyvXMeta || {},
    alias: createacr79XtyvXMeta?.alias || [],
    redirect: createacr79XtyvXMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src2383342895/src/sense-us.app/pages/admin/project/[projectId]/round/create.vue").then(m => m.default || m)
  },
  {
    name: createacr79XtyvXMeta?.name ?? "admin-project-projectId-round-create___nl",
    path: createacr79XtyvXMeta?.path ?? "/nl/admin/project/:projectId()/round/create",
    meta: createacr79XtyvXMeta || {},
    alias: createacr79XtyvXMeta?.alias || [],
    redirect: createacr79XtyvXMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src2383342895/src/sense-us.app/pages/admin/project/[projectId]/round/create.vue").then(m => m.default || m)
  },
  {
    name: createacr79XtyvXMeta?.name ?? "admin-project-projectId-round-create___de",
    path: createacr79XtyvXMeta?.path ?? "/de/admin/project/:projectId()/round/create",
    meta: createacr79XtyvXMeta || {},
    alias: createacr79XtyvXMeta?.alias || [],
    redirect: createacr79XtyvXMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src2383342895/src/sense-us.app/pages/admin/project/[projectId]/round/create.vue").then(m => m.default || m)
  },
  {
    name: createacr79XtyvXMeta?.name ?? "admin-project-projectId-round-create___es",
    path: createacr79XtyvXMeta?.path ?? "/es/admin/project/:projectId()/round/create",
    meta: createacr79XtyvXMeta || {},
    alias: createacr79XtyvXMeta?.alias || [],
    redirect: createacr79XtyvXMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src2383342895/src/sense-us.app/pages/admin/project/[projectId]/round/create.vue").then(m => m.default || m)
  },
  {
    name: _91userId_938Hb9jkmGtxMeta?.name ?? "admin-project-projectId-users-userId___en",
    path: _91userId_938Hb9jkmGtxMeta?.path ?? "/admin/project/:projectId()/users/:userId()",
    meta: _91userId_938Hb9jkmGtxMeta || {},
    alias: _91userId_938Hb9jkmGtxMeta?.alias || [],
    redirect: _91userId_938Hb9jkmGtxMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src2383342895/src/sense-us.app/pages/admin/project/[projectId]/users/[userId].vue").then(m => m.default || m)
  },
  {
    name: _91userId_938Hb9jkmGtxMeta?.name ?? "admin-project-projectId-users-userId___nl",
    path: _91userId_938Hb9jkmGtxMeta?.path ?? "/nl/admin/project/:projectId()/users/:userId()",
    meta: _91userId_938Hb9jkmGtxMeta || {},
    alias: _91userId_938Hb9jkmGtxMeta?.alias || [],
    redirect: _91userId_938Hb9jkmGtxMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src2383342895/src/sense-us.app/pages/admin/project/[projectId]/users/[userId].vue").then(m => m.default || m)
  },
  {
    name: _91userId_938Hb9jkmGtxMeta?.name ?? "admin-project-projectId-users-userId___de",
    path: _91userId_938Hb9jkmGtxMeta?.path ?? "/de/admin/project/:projectId()/users/:userId()",
    meta: _91userId_938Hb9jkmGtxMeta || {},
    alias: _91userId_938Hb9jkmGtxMeta?.alias || [],
    redirect: _91userId_938Hb9jkmGtxMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src2383342895/src/sense-us.app/pages/admin/project/[projectId]/users/[userId].vue").then(m => m.default || m)
  },
  {
    name: _91userId_938Hb9jkmGtxMeta?.name ?? "admin-project-projectId-users-userId___es",
    path: _91userId_938Hb9jkmGtxMeta?.path ?? "/es/admin/project/:projectId()/users/:userId()",
    meta: _91userId_938Hb9jkmGtxMeta || {},
    alias: _91userId_938Hb9jkmGtxMeta?.alias || [],
    redirect: _91userId_938Hb9jkmGtxMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src2383342895/src/sense-us.app/pages/admin/project/[projectId]/users/[userId].vue").then(m => m.default || m)
  },
  {
    name: indexxSggPLRZvdMeta?.name ?? "admin-project-projectId-users___en",
    path: indexxSggPLRZvdMeta?.path ?? "/admin/project/:projectId()/users",
    meta: indexxSggPLRZvdMeta || {},
    alias: indexxSggPLRZvdMeta?.alias || [],
    redirect: indexxSggPLRZvdMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src2383342895/src/sense-us.app/pages/admin/project/[projectId]/users/index.vue").then(m => m.default || m)
  },
  {
    name: indexxSggPLRZvdMeta?.name ?? "admin-project-projectId-users___nl",
    path: indexxSggPLRZvdMeta?.path ?? "/nl/admin/project/:projectId()/users",
    meta: indexxSggPLRZvdMeta || {},
    alias: indexxSggPLRZvdMeta?.alias || [],
    redirect: indexxSggPLRZvdMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src2383342895/src/sense-us.app/pages/admin/project/[projectId]/users/index.vue").then(m => m.default || m)
  },
  {
    name: indexxSggPLRZvdMeta?.name ?? "admin-project-projectId-users___de",
    path: indexxSggPLRZvdMeta?.path ?? "/de/admin/project/:projectId()/users",
    meta: indexxSggPLRZvdMeta || {},
    alias: indexxSggPLRZvdMeta?.alias || [],
    redirect: indexxSggPLRZvdMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src2383342895/src/sense-us.app/pages/admin/project/[projectId]/users/index.vue").then(m => m.default || m)
  },
  {
    name: indexxSggPLRZvdMeta?.name ?? "admin-project-projectId-users___es",
    path: indexxSggPLRZvdMeta?.path ?? "/es/admin/project/:projectId()/users",
    meta: indexxSggPLRZvdMeta || {},
    alias: indexxSggPLRZvdMeta?.alias || [],
    redirect: indexxSggPLRZvdMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src2383342895/src/sense-us.app/pages/admin/project/[projectId]/users/index.vue").then(m => m.default || m)
  },
  {
    name: createC7q1gcR93zMeta?.name ?? "admin-project-create___en",
    path: createC7q1gcR93zMeta?.path ?? "/admin/project/create",
    meta: createC7q1gcR93zMeta || {},
    alias: createC7q1gcR93zMeta?.alias || [],
    redirect: createC7q1gcR93zMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src2383342895/src/sense-us.app/pages/admin/project/create.vue").then(m => m.default || m)
  },
  {
    name: createC7q1gcR93zMeta?.name ?? "admin-project-create___nl",
    path: createC7q1gcR93zMeta?.path ?? "/nl/admin/project/create",
    meta: createC7q1gcR93zMeta || {},
    alias: createC7q1gcR93zMeta?.alias || [],
    redirect: createC7q1gcR93zMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src2383342895/src/sense-us.app/pages/admin/project/create.vue").then(m => m.default || m)
  },
  {
    name: createC7q1gcR93zMeta?.name ?? "admin-project-create___de",
    path: createC7q1gcR93zMeta?.path ?? "/de/admin/project/create",
    meta: createC7q1gcR93zMeta || {},
    alias: createC7q1gcR93zMeta?.alias || [],
    redirect: createC7q1gcR93zMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src2383342895/src/sense-us.app/pages/admin/project/create.vue").then(m => m.default || m)
  },
  {
    name: createC7q1gcR93zMeta?.name ?? "admin-project-create___es",
    path: createC7q1gcR93zMeta?.path ?? "/es/admin/project/create",
    meta: createC7q1gcR93zMeta || {},
    alias: createC7q1gcR93zMeta?.alias || [],
    redirect: createC7q1gcR93zMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src2383342895/src/sense-us.app/pages/admin/project/create.vue").then(m => m.default || m)
  },
  {
    name: indexN4AtGORVQ4Meta?.name ?? "index___en",
    path: indexN4AtGORVQ4Meta?.path ?? "/",
    meta: indexN4AtGORVQ4Meta || {},
    alias: indexN4AtGORVQ4Meta?.alias || [],
    redirect: indexN4AtGORVQ4Meta?.redirect || undefined,
    component: () => import("/codebuild/output/src2383342895/src/sense-us.app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexN4AtGORVQ4Meta?.name ?? "index___nl",
    path: indexN4AtGORVQ4Meta?.path ?? "/nl",
    meta: indexN4AtGORVQ4Meta || {},
    alias: indexN4AtGORVQ4Meta?.alias || [],
    redirect: indexN4AtGORVQ4Meta?.redirect || undefined,
    component: () => import("/codebuild/output/src2383342895/src/sense-us.app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexN4AtGORVQ4Meta?.name ?? "index___de",
    path: indexN4AtGORVQ4Meta?.path ?? "/de",
    meta: indexN4AtGORVQ4Meta || {},
    alias: indexN4AtGORVQ4Meta?.alias || [],
    redirect: indexN4AtGORVQ4Meta?.redirect || undefined,
    component: () => import("/codebuild/output/src2383342895/src/sense-us.app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexN4AtGORVQ4Meta?.name ?? "index___es",
    path: indexN4AtGORVQ4Meta?.path ?? "/es",
    meta: indexN4AtGORVQ4Meta || {},
    alias: indexN4AtGORVQ4Meta?.alias || [],
    redirect: indexN4AtGORVQ4Meta?.redirect || undefined,
    component: () => import("/codebuild/output/src2383342895/src/sense-us.app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: login07hQJgC3aPMeta?.name ?? "login___en",
    path: login07hQJgC3aPMeta?.path ?? "/login",
    meta: login07hQJgC3aPMeta || {},
    alias: login07hQJgC3aPMeta?.alias || [],
    redirect: login07hQJgC3aPMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src2383342895/src/sense-us.app/pages/login.vue").then(m => m.default || m)
  },
  {
    name: login07hQJgC3aPMeta?.name ?? "login___nl",
    path: login07hQJgC3aPMeta?.path ?? "/nl/login",
    meta: login07hQJgC3aPMeta || {},
    alias: login07hQJgC3aPMeta?.alias || [],
    redirect: login07hQJgC3aPMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src2383342895/src/sense-us.app/pages/login.vue").then(m => m.default || m)
  },
  {
    name: login07hQJgC3aPMeta?.name ?? "login___de",
    path: login07hQJgC3aPMeta?.path ?? "/de/login",
    meta: login07hQJgC3aPMeta || {},
    alias: login07hQJgC3aPMeta?.alias || [],
    redirect: login07hQJgC3aPMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src2383342895/src/sense-us.app/pages/login.vue").then(m => m.default || m)
  },
  {
    name: login07hQJgC3aPMeta?.name ?? "login___es",
    path: login07hQJgC3aPMeta?.path ?? "/es/login",
    meta: login07hQJgC3aPMeta || {},
    alias: login07hQJgC3aPMeta?.alias || [],
    redirect: login07hQJgC3aPMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src2383342895/src/sense-us.app/pages/login.vue").then(m => m.default || m)
  },
  {
    name: registerjPkZHeOMcQMeta?.name ?? "register___en",
    path: registerjPkZHeOMcQMeta?.path ?? "/register",
    meta: registerjPkZHeOMcQMeta || {},
    alias: registerjPkZHeOMcQMeta?.alias || [],
    redirect: registerjPkZHeOMcQMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src2383342895/src/sense-us.app/pages/register.vue").then(m => m.default || m)
  },
  {
    name: registerjPkZHeOMcQMeta?.name ?? "register___nl",
    path: registerjPkZHeOMcQMeta?.path ?? "/nl/register",
    meta: registerjPkZHeOMcQMeta || {},
    alias: registerjPkZHeOMcQMeta?.alias || [],
    redirect: registerjPkZHeOMcQMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src2383342895/src/sense-us.app/pages/register.vue").then(m => m.default || m)
  },
  {
    name: registerjPkZHeOMcQMeta?.name ?? "register___de",
    path: registerjPkZHeOMcQMeta?.path ?? "/de/register",
    meta: registerjPkZHeOMcQMeta || {},
    alias: registerjPkZHeOMcQMeta?.alias || [],
    redirect: registerjPkZHeOMcQMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src2383342895/src/sense-us.app/pages/register.vue").then(m => m.default || m)
  },
  {
    name: registerjPkZHeOMcQMeta?.name ?? "register___es",
    path: registerjPkZHeOMcQMeta?.path ?? "/es/register",
    meta: registerjPkZHeOMcQMeta || {},
    alias: registerjPkZHeOMcQMeta?.alias || [],
    redirect: registerjPkZHeOMcQMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src2383342895/src/sense-us.app/pages/register.vue").then(m => m.default || m)
  }
]